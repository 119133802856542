import * as React from 'react'
import {useEffect, useState} from 'react'
import Logo from '../images/stadspiratie-logo.svg'
import Twitter from '../images/twitter.svg'
import Facebook from '../images/facebook.svg'
import "../style/headerBar.css"

const HeaderBar = ({siteTitle, link}) => {
  const [loaded, setLoaded] = useState(0);

  useEffect(() => {
    setLoaded(1);
  });

  return (
    <header className="headerBar">
      <div className="headerPositioning">
        <a key={loaded} href={link}>
          <img className="logo" src={Logo}/>
        </a>
        <h1 className="headerTitle">
          {siteTitle}
        </h1>
        <div>
          <a target={'_blank'}
             href="https://www.facebook.com/dialog/share?display=popup&redirect_uri=https://www.stadspiratie.be&app_id=2594506700781089">
            <img className="icon" src={Facebook}/>
          </a>

          <a target={'_blank'}
             href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.stadspiratie.be%2F&text=">
            <img className="icon" src={Twitter}/>
          </a>
        </div>
      </div>
    </header>
  )
}

export default HeaderBar
